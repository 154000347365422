import React, { useContext } from "react";
import { firestore }  from '../Components/Firestore'
import { UserDataContext } from "../Components/UserDataProvider";

const PrinterOverview = (props) => {

    const { userData } = useContext(UserDataContext);

    const changeSEtoken = (newValue) => {
        if (newValue === userData.SEToken || newValue === null) {return;}
        firestore.collection("users").doc(userData.id).update({
            SEToken: newValue
        })
    }

    return ( 
        <div>
            <label htmlFor="i1" className="block text-base font-medium leading-5 text-gray-700 mt-6">Stream Elements Token</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input type="password" id="i1" className="form-input block w-full" defaultValue={userData.SEToken} onBlur={(event) => changeSEtoken(event.target.value)}/>
            </div>
            <p className="mt-2 text-base text-gray-500">Required for your printer to function</p>
        </div>
     );
}
 
export default PrinterOverview;