import React, {useContext, useState} from 'react';
import { firestore }  from './Firestore'
import { AuthContext } from './Auth';
import { Loadingscreen } from './Loading'

export const UserDataContext = React.createContext();

export const UserDataProvider = ({ children }) => {

	const {currentUser} = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  
  if (!userData && currentUser){
    // Get the user in the DB matching the uid in the Auth object
    firestore.collection("users").doc(currentUser.uid).get().then(user => {
      setUserData(user.data())
    })

    return <Loadingscreen loadingText="Loading userdata..."/>
  }

  return (
    <UserDataContext.Provider value={{userData}}>
      {children}
    </UserDataContext.Provider>
  );
}; 