import React from 'react';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import Product from './Product/Product';
import Buy from './Buy/Buy'

// Routing
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./Components/Auth";
import { PrivateRoute } from "./Components/PrivateRoute";
import { UserDataProvider } from "./Components/UserDataProvider";

import { version } from "../package.json"

// Styling
import './App.css';
import './tailwind.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faDonate, faHome } from '@fortawesome/free-solid-svg-icons'
library.add( faSignOutAlt, faDonate, faHome )

const App = () => {

  return (
  	<AuthProvider>
      <div className="fixed bottom-2 right-2 text-white">v{version}</div>
	    <Router>
        <Switch>
        	<Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
        	<UserDataProvider>
        		<PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/products/:slug/:id" component={Product} />
            <PrivateRoute path="/buy" component={Buy} />
        	</UserDataProvider>
        </Switch>
	    </Router>
    </AuthProvider>
  );
}

export default App;