import React from "react";

export const Loadingscreen = (props) => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="w-5 sm:w-8 md:w-12 mb-8" alt="sd_loader">
        <svg version="1.1" viewBox="0 0 49 85" xmlns="http://www.w3.org/2000/svg">
          <path fill="#fff" d="M8.71,47.93v14C8.79,71.29,15,77.65,24,77.68c9.24,0,15.81-6.4,15.92-15.81.1-10,0-19.9,0-29.85,0-2.54.18-5,2.6-6.62,1.58-1,3.23-1.23,4.72.09a3.05,3.05,0,0,1,.62,4.24c-2.12,2.45-1.6,5.22-1.61,7.94v25.2C46.1,75,37,84.45,25.31,84.65c-13.17.21-22.9-8.58-23.23-21.38-.23-9,0-18,0-27,0-2.15.2-4.28-1.34-6.21a3.1,3.1,0,0,1,.35-4.37l.27-.2c1.71-1.44,3.51-1,5.08.28,2,1.65,2.31,4,2.32,6.35Z" data-name="vaas" />
          <path fill="#fff" d="m38 63.62c-1.13 7.63-7.26 12.3-15.3 11.8-6.85-0.42-12.12-6.06-12.44-13.32-0.39-8.92-0.4-9 8.47-9.43 4-0.2 7.58-1.6 11.22-3 7.52-2.78 8.36-2.22 8.36 5.52 0 0.67 0.04 6.37-0.31 8.43z" data-name="vloeistof" />
          <path fill="#fff" d="m33.71 40.52c1.36 0 2.75-0.08 2.78 1.57 0 2.07-1.73 1.72-3 1.74s-2.76 0.1-2.8-1.55c-0.09-1.93 1.1-1.73 3.02-1.76z" data-name="stip1" />
          <path fill="#fff" d="m33.71 34.71c1.36 0 2.75-0.08 2.78 1.57 0 2.07-1.73 1.72-3 1.75s-2.76 0.09-2.8-1.56c-0.09-1.92 1.1-1.73 3.02-1.76z" data-name="stip2" />
          <path fill="#fff" d="m33.71 28.5c1.36 0 2.75-0.08 2.78 1.57 0 2.07-1.73 1.72-3 1.75s-2.76 0.09-2.8-1.56c-0.09-1.92 1.1-1.73 3.02-1.76z" data-name="stip3" />
          <path fill="#fff" d="M24.3,0A27.35,27.35,0,0,1,43.21,7.68a2,2,0,0,1-.07,2.83h0a2,2,0,0,1-2.72,0A22.38,22.38,0,0,0,30.28,4.86,23,23,0,0,0,8.71,10c-.25.21-.47.44-.72.65a1.92,1.92,0,0,1-2.68-.11,1.91,1.91,0,0,1-.2-2.68l0,0h0a19,19,0,0,1,2.94-2.5A27.49,27.49,0,0,1,24.3,0Z" opacity="0" data-name="wifi-top">
            <animate attributeName="opacity" begin="0.5s" dur="1s" repeatCount="indefinite" values="1;0;" />
          </path>
          <path fill="#fff" d="M24.88,7.45A19.83,19.83,0,0,1,38,12.93a2,2,0,0,1,.36,2.8h0a2.05,2.05,0,0,1-.75.59A1.9,1.9,0,0,1,35.35,16,15.85,15.85,0,0,0,14,15.27c-.28.23-.55.49-.81.74a2,2,0,0,1-2.82-2.88,18.78,18.78,0,0,1,4.5-3.27A20.51,20.51,0,0,1,24.88,7.45Z" opacity="0" data-name="wifi-middle">
            <animate attributeName="opacity" begin="0.25s" dur="1s" repeatCount="indefinite" values="1;0;" />
          </path>
          <path fill="#fff" d="M24.24,14.86A12.43,12.43,0,0,1,33,18.52,2,2,0,0,1,33.32,21a2,2,0,0,1-2.36.88,1.8,1.8,0,0,1-.64-.41,8.35,8.35,0,0,0-11.81-.24h0c-.06.06-.13.12-.19.19a2,2,0,0,1-2.35.36,2.16,2.16,0,0,1-1.11-2.3,1.59,1.59,0,0,1,.41-.74A12.25,12.25,0,0,1,22.34,15c.43-.07.86-.11,1.29-.15A4.14,4.14,0,0,1,24.24,14.86Z" opacity="0" data-name="wifi-bottom" >
            <animate attributeName="opacity" dur="1s" repeatCount="indefinite" values="1;0;" />
          </path>
        </svg>
      </div>
      <h1 className="font-semibold text-white">{props.loadingText}</h1>
    </div>
  );
};

export const Loader = (props) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="w-5 sm:w-8 md:w-12 mb-8" alt="sd_loader">
        <svg version="1.1" viewBox="0 0 49 85" xmlns="http://www.w3.org/2000/svg">
          <path fill={props.color} d="M8.71,47.93v14C8.79,71.29,15,77.65,24,77.68c9.24,0,15.81-6.4,15.92-15.81.1-10,0-19.9,0-29.85,0-2.54.18-5,2.6-6.62,1.58-1,3.23-1.23,4.72.09a3.05,3.05,0,0,1,.62,4.24c-2.12,2.45-1.6,5.22-1.61,7.94v25.2C46.1,75,37,84.45,25.31,84.65c-13.17.21-22.9-8.58-23.23-21.38-.23-9,0-18,0-27,0-2.15.2-4.28-1.34-6.21a3.1,3.1,0,0,1,.35-4.37l.27-.2c1.71-1.44,3.51-1,5.08.28,2,1.65,2.31,4,2.32,6.35Z" data-name="vaas" />
          <path fill={props.color} d="m38 63.62c-1.13 7.63-7.26 12.3-15.3 11.8-6.85-0.42-12.12-6.06-12.44-13.32-0.39-8.92-0.4-9 8.47-9.43 4-0.2 7.58-1.6 11.22-3 7.52-2.78 8.36-2.22 8.36 5.52 0 0.67 0.04 6.37-0.31 8.43z" data-name="vloeistof" />
          <path fill={props.color} d="m33.71 40.52c1.36 0 2.75-0.08 2.78 1.57 0 2.07-1.73 1.72-3 1.74s-2.76 0.1-2.8-1.55c-0.09-1.93 1.1-1.73 3.02-1.76z" data-name="stip1" />
          <path fill={props.color} d="m33.71 34.71c1.36 0 2.75-0.08 2.78 1.57 0 2.07-1.73 1.72-3 1.75s-2.76 0.09-2.8-1.56c-0.09-1.92 1.1-1.73 3.02-1.76z" data-name="stip2" />
          <path fill={props.color} d="m33.71 28.5c1.36 0 2.75-0.08 2.78 1.57 0 2.07-1.73 1.72-3 1.75s-2.76 0.09-2.8-1.56c-0.09-1.92 1.1-1.73 3.02-1.76z" data-name="stip3" />
          <path fill={props.color} d="M24.3,0A27.35,27.35,0,0,1,43.21,7.68a2,2,0,0,1-.07,2.83h0a2,2,0,0,1-2.72,0A22.38,22.38,0,0,0,30.28,4.86,23,23,0,0,0,8.71,10c-.25.21-.47.44-.72.65a1.92,1.92,0,0,1-2.68-.11,1.91,1.91,0,0,1-.2-2.68l0,0h0a19,19,0,0,1,2.94-2.5A27.49,27.49,0,0,1,24.3,0Z" opacity="0" data-name="wifi-top">
            <animate attributeName="opacity" begin="0.5s" dur="1s" repeatCount="indefinite" values="1;0;" />
          </path>
          <path fill={props.color} d="M24.88,7.45A19.83,19.83,0,0,1,38,12.93a2,2,0,0,1,.36,2.8h0a2.05,2.05,0,0,1-.75.59A1.9,1.9,0,0,1,35.35,16,15.85,15.85,0,0,0,14,15.27c-.28.23-.55.49-.81.74a2,2,0,0,1-2.82-2.88,18.78,18.78,0,0,1,4.5-3.27A20.51,20.51,0,0,1,24.88,7.45Z" opacity="0" data-name="wifi-middle">
            <animate attributeName="opacity" begin="0.25s" dur="1s" repeatCount="indefinite" values="1;0;" />
          </path>
          <path fill={props.color} d="M24.24,14.86A12.43,12.43,0,0,1,33,18.52,2,2,0,0,1,33.32,21a2,2,0,0,1-2.36.88,1.8,1.8,0,0,1-.64-.41,8.35,8.35,0,0,0-11.81-.24h0c-.06.06-.13.12-.19.19a2,2,0,0,1-2.35.36,2.16,2.16,0,0,1-1.11-2.3,1.59,1.59,0,0,1,.41-.74A12.25,12.25,0,0,1,22.34,15c.43-.07.86-.11,1.29-.15A4.14,4.14,0,0,1,24.24,14.86Z" opacity="0" data-name="wifi-bottom" >
            <animate attributeName="opacity" dur="1s" repeatCount="indefinite" values="1;0;" />
          </path>
        </svg>
      </div>
      {props.loadingText && (
        <h1 className="font-semibold text-white" style={{color: props.color}}>{props.loadingText}</h1>
      )}
    </div>
  );
};

