import React, { useEffect, useState } from "react";
import { firestore } from "./Firestore";
import { Loadingscreen } from "./Loading";
import axios from "axios";

import firebase from "firebase/app";
import "firebase/auth";

const googleProvider = new firebase.auth.GoogleAuthProvider();
const twitchProvider = new firebase.auth.OAuthProvider("oidc.twitch");

export const LoginWithTwitch = () => {
  firebase
    .auth()
    .signInWithPopup(twitchProvider)
    .then((cred) => {
      console.log(cred);

      axios.get(
        `https://us-central1-streamdoctors-dashboard.cloudfunctions.net/api`
      );

      let user = firestore.collection("users").doc(cred.user.uid);

      user.get().then((userRef) => {
        if (userRef.exists) {
          user.update({
            firstName: cred.additionalUserInfo.profile.preferred_username,
          });
        } else {
          user.set({
            provider: "Twitch",
            firstName: cred.additionalUserInfo.profile.preferred_username,
            products: [],
            SEToken: "",
          });
        }
      });
    })
    .catch((e) => console.log("loginWithTwitch Error", e));
};

export const LoginWithGoogle = () => {
  firebase
    .auth()
    .signInWithPopup(googleProvider)
    .then((cred) => {
      axios.get(
        `https://us-central1-streamdoctors-dashboard.cloudfunctions.net/api`
      );

      let user = firestore.collection("users").doc(cred.user.uid);

      user.get().then((userRef) => {
        if (userRef.exists) {
          user.update({
            firstName: cred.additionalUserInfo.profile.given_name,
            lastName: cred.additionalUserInfo.profile.family_name,
            email: cred.additionalUserInfo.profile.email,
            fullName: cred.additionalUserInfo.profile.name,
            profilePicture: cred.additionalUserInfo.profile.picture,
            id: cred.user.uid,
          });
        } else {
          user.set({
            provider: "Google",
            firstName: cred.additionalUserInfo.profile.given_name,
            lastName: cred.additionalUserInfo.profile.family_name,
            email: cred.additionalUserInfo.profile.email,
            fullName: cred.additionalUserInfo.profile.name,
            profilePicture: cred.additionalUserInfo.profile.picture,
            products: [],
            SEToken: "",
            id: cred.user.uid,
          });
        }
      });
    })
    .catch((e) => console.log("loginWithGoogle Error", e));
};

export const Logout = () => {
  firebase.auth().signOut();
};

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return <Loadingscreen loadingText={"Authenticating user..."} />;
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
