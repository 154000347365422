import React from 'react';
import Header from "../Components/Header";
import Printer from "./Printer"

const Product = () => {

  	return (
		<React.Fragment>
			<Header />
			<div className="mx-auto container my-10">
				
				<div className="bg-gray-200 overflow-hidden shadow sm:rounded-lg">
					<Printer/>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Product;