import React from "react";
import ProductList from "./ProductList";
import Header from "../Components/Header"

// import { firestore } from '../Components/Firestore'

const Dashboard = () => {

    // async function createNewPrinter() {
    //   const document = await firestore.collection("products").doc("printer").collection("printers").doc('DEFAULT').get()

    //   firestore.collection("products").doc("printer").collection("printers")
    //     .add(document.data()).then((docRef) => {
    //       console.log("Document written with ID: ", docRef.id);
    //     })
    //     .catch((error) => {
    //       console.error("Error adding document: ", error);
    //     });
    // }
  
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <ProductList />
      {/* <button onClick={() => createNewPrinter()} className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
        Create New Printer
      </button> */}
    </div>
  );
};

export default Dashboard;
