import React from "react";
import Coupon from "./Coupon";
import { firestore } from "../Components/Firestore";

const PrinterEvent = (props) => {
  const changeThreshold = (newValue) => {
    if (newValue === props.eventData.threshold || newValue === null) return;

    firestore
      .collection("products")
      .doc("printer")
      .collection("printers")
      .doc(props.printerID)
      .update({
        [props.eventType + ".threshold"]: newValue,
      });
  };

  let threshold;
  if (props.eventData.threshold) {
    threshold = (
      <div>
        <label
          htmlFor="threshold"
          className="block text-base font-medium leading-5 text-gray-700 mt-6"
        >
          Ticket Threshold
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="number"
            id="threshold"
            className="form-input block w-full"
            defaultValue={props.eventData.threshold}
            onBlur={(event) => changeThreshold(event.target.value)}
          />
        </div>
        <p className="mt-2 text-base text-gray-500">
          The minimum amount of <strong>{props.eventTypeName}</strong> required
          to print a ticket
        </p>
      </div>
    );
  }

  let toggleColor;
  let togglePos;

  if (props.eventData.active) {
    toggleColor = "bg-green-400";
    togglePos = "translate-x-5";
  }

  return (
    <React.Fragment>
      <div className="flex flex-col">
        <label className="text-lg font-semibold" htmlFor="c1">
          Active:
        </label>
        <span
          id="c1"
          onClick={() =>
            props.changeActiveState(props.eventData.active, props.eventType)
          }
          className={
            toggleColor +
            " bg-gray-300 relative inline-flex flex-shrink-0 ml-2 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
          }
        >
          <span
            className={
              togglePos +
              " translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
            }
          ></span>
        </span>
        {threshold}
      </div>
      <Coupon
        eventType={props.eventType}
        content={props.eventData.content}
        printerID={props.printerID}
      />
    </React.Fragment>
  );
};

export default PrinterEvent;
