import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { useRouteMatch } from "react-router";

const ProductNav = ({ printerData }) => {
  const { url } = useRouteMatch();

  return (
    <div>
      {/* <div className="md:hidden">
	        <select className="form-select block w-full">
	          <option>Followers</option>
	          <option>Donations</option>
	          <option>Subscribers</option>
	          <option>Bits</option>
	          <option>Raids</option>
			  <option>Hosts</option>
	        </select>
	      </div> */}
      <div className="hidden md:block">
        <div className="border-b border-gray-200 bg-white px-8">
          <nav className="flex -mb-px">
            <NavLink
              to={url}
              exact
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <FontAwesomeIcon
                icon="home"
                className="-ml-0.5 text-xl mr-2 h-5 w-5 group-focus:text-indigo-600"
              />
              <span>Dashboard</span>
            </NavLink>
            <NavLink
              to={url + "/followers"}
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <svg
                className="-ml-0.5 mr-2 h-5 w-5 group-focus:text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              <span>Followers</span>
            </NavLink>
            <NavLink
              to={url + "/donations"}
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <FontAwesomeIcon
                icon="donate"
                className="-ml-0.5 text-xl mr-2 h-5 w-5 group-focus:text-indigo-600"
              />
              <span>Donations</span>
            </NavLink>
            <NavLink
              to={url + "/subscribers"}
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <svg
                className="-ml-0.5 mr-2 h-5 w-5 group-focus:text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              <span>Subscribers</span>
            </NavLink>
            <NavLink
              to={url + "/resubs"}
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <svg
                className="-ml-0.5 mr-2 h-5 w-5 group-focus:text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              <span>Resubs</span>
            </NavLink>
            <NavLink
              to={url + "/giftsubs"}
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <svg
                className="-ml-0.5 mr-2 h-5 w-5 group-focus:text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              <span>Giftsubs</span>
            </NavLink>
            <NavLink
              to={url + "/bits"}
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <svg
                className="-ml-0.5 mr-2 h-5 w-5 group-focus:text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 12l7-10 7 10-7 6-7-6zm2.678-.338L10 5.487l4.322 6.173-.85.728L10 11l-3.473 1.39-.849-.729z"
                />
              </svg>
              <span>Bits</span>
            </NavLink>
            <NavLink
              to={url + "/raids"}
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <svg
                className="-ml-0.5 mr-2 h-5 w-5  group-focus:text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                <path
                  fillRule="evenodd"
                  d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Raids</span>
            </NavLink>
            <NavLink
              to={url + "/hosts"}
              activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 mr-3"
            >
              <svg
                className="-ml-0.5 mr-2 h-5 w-5 group-focus:text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                <path
                  fillRule="evenodd"
                  d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Hosts</span>
            </NavLink>
            {printerData.webshop && (
              <NavLink
                to={url + "/webshop"}
                activeClassName="border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-700 focus:border-indigo-700"
                className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 "
              >
                <svg
                  className="-ml-0.5 mr-2 h-5 w-5 group-focus:text-indigo-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                </svg>
                <span>Webshop</span>
              </NavLink>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ProductNav;
