import React from 'react'

import Header from "../Components/Header"
import { Loader } from '../Components/Loading'

const Buy = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <Loader color={'#a0aec0'} loadingText={"No products yet..."} />
        </div>
    )
}

export default Buy;