import React from "react";
import cloneDeep from 'lodash.clonedeep';
import { firestore }  from '../Components/Firestore'

const Coupon = (props) => {
  
    const editContentItem = (index) => {

        // 1. Make deepclone of entire content array -> (cloneDeep(contenArray))
        const contentArr = cloneDeep(props.content)

        // 2. Store a reference to the item in the array that needs to be edited
        const contentItem = contentArr[index]

        // 3 Show prompt 
        let oldVal = contentItem.data
        let newVal = prompt("Please edit your content", oldVal);

        // 4. If null or no changes made, return
        if (newVal === null || newVal === oldVal ) { return; }

        // 5. Modify the entry
        contentItem.data = newVal

        // 6. Update Firestore with the new content array
        firestore.collection("products").doc("printer").collection("printers").doc(props.printerID).update({
            [props.eventType + ".content"]: contentArr
        })
    }

    const content = props.content;
    let couponContent;

    if (content) {
        couponContent = (
            <div className="bg-white shadow-lg p-6 flex flex-col items-center" style={{width:'500px'}}>
                {content.map((contentItem, index) => {

                    switch (contentItem.type) {

                        case "header":
                            return (
                                <div onClick={() => editContentItem(index)} className="hover:bg-gray-100 rounded cursor-pointer w-full flex flex-col items-center justify-between py-2" key={index}>
                                    <h1 className="text-3xl font-semibold">{contentItem.data}</h1>
                                </div>
                            )

                        case "subheader":
                            return (
                                <div onClick={() => editContentItem(index)} className="hover:bg-gray-100 rounded cursor-pointer w-full flex flex-col items-center justify-between py-2" key={index}>
                                    <h2 className="text-xl font-semibold">{contentItem.data}</h2>
                                </div>
                            )

                        case "text":
                            return (
                                <div onClick={() => editContentItem(index)} className="hover:bg-gray-100 rounded cursor-pointer w-full flex flex-col items-center justify-between py-2" key={index}>
                                    <p className="text-base">{contentItem.data}</p>
                                </div>
                            )

                        case "img":
                            return (
                                <div onClick={() => editContentItem(index)} className="hover:bg-gray-100 rounded cursor-pointer w-full flex flex-col items-center justify-between py-2" key={index}>
                                    <img  className="max-h-64" src={contentItem.data} alt="image_receipt"/>
                                </div>
                            )

                        default:
                            break;
                    }

                    return (
                        <div key={index}>No content yet!</div>
                    );
                })}
            </div>
        )

    }

    return (

        <React.Fragment>
            {couponContent}
        </React.Fragment>

    );
};

export default Coupon;                 