import React, { useContext } from "react";
import { AuthContext, LoginWithGoogle, LoginWithTwitch } from "../Components/Auth";
import { Redirect } from "react-router-dom";

const Login = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div>
      {currentUser && <Redirect to={"/dashboard"} />}

      {!currentUser ? (
        <div className="min-h-screen flex flex-col items-center justify-center -mt-20">
          <img
            className="h-32 select-none pointer-events-none mb-8"
            alt="streamdoctors_logo"
            src="https://firebasestorage.googleapis.com/v0/b/streamdoctors-dashboard.appspot.com/o/SD-Wit.png?alt=media&token=707daf8c-1fcf-4e71-b032-a927d538f141"
          />
          <div className="flex items-center justify-center">
            <button onClick={() => LoginWithTwitch()} className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
              Log in with Twitch
            </button>
          </div>
          <div className="flex items-center justify-center">
            <button onClick={() => LoginWithGoogle()} className="bg-blue-500 hover:bg-blue-700 mt-4 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
              Log in with Google
            </button>
          </div>
        </div>
      ) : (
        <pre>{JSON.stringify(currentUser, null, 2)}</pre>
      )}
    </div>
  );
};

export default Login;
