import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { UserDataContext } from './UserDataProvider';
import { Logout } from './Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = (props) => {

  const { userData } = useContext(UserDataContext);

  return (
    <div>
      <nav className="mx-auto container flex justify-between items-center py-5 px-4 text-lg">
        <Link to={"/dashboard"}>
          <img className="w-56 md:w-128 mr-auto" alt="streamdoctors_logo" src="/logo.png" />
        </Link>
        <div className="flex">
          <div className="mr-5 -mt-1 text-white text-2xl font-medium flex items-center">{userData.firstName}</div>
          <button className="text-blue-400 flex items-center" onClick={() => Logout()}>
            <FontAwesomeIcon icon="sign-out-alt" className="text-white text-2xl"/>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Header;
