import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { useParams } from "react-router";

import { firestore } from "../Components/Firestore";
import { Loader } from "../Components/Loading";

import ProductNav from "./ProductNav";
import PrinterOverview from "./PrinterOverview";
import PrinterEvent from "./PrinterEvent";

const Printer = () => {
  const { id } = useParams();
  const { url } = useRouteMatch();

  const firestorePrinterPath = firestore
    .collection("products")
    .doc("printer")
    .collection("printers")
    .doc(id);

  const changeActiveState = (currentState, eventType) => {
    firestorePrinterPath.update({ [eventType + ".active"]: !currentState });
  };

  const [printerData, setPrinterData] = useState(false);

  useEffect(() => {
    const unsubscribe = firestore
      .collection("products")
      .doc("printer")
      .collection("printers")
      .doc(id)
      .onSnapshot((snap) => {
        const data = snap.data();
        setPrinterData(data);
      });

    //Unsubscribe from listener on unmount
    return () => unsubscribe();
  }, [id]);

  let printerEventContent;
  let toggleColor;
  let togglePos;

  if (printerData.active) {
    toggleColor = "bg-green-400";
    togglePos = "translate-x-5";
  }

  if (!printerData) {
    printerEventContent = <Loader color={"#a0aec0"} />;
  } else {
    printerEventContent = (
      <React.Fragment>
        <Route exact path={url + "/"} render={() => <PrinterOverview />} />
        <Route
          path={url + "/followers"}
          render={(routeProps) => (
            <PrinterEvent
              {...routeProps}
              eventData={printerData.follow}
              eventType={"follow"}
              eventTypeName={"Followers"}
              changeActiveState={changeActiveState}
              printerID={id}
            />
          )}
        />
        <Route
          path={url + "/donations"}
          render={(routeProps) => (
            <PrinterEvent
              {...routeProps}
              eventData={printerData.donation}
              eventType={"donation"}
              eventTypeName={"Donations"}
              changeActiveState={changeActiveState}
              printerID={id}
            />
          )}
        />
        <Route
          path={url + "/subscribers"}
          render={(routeProps) => (
            <PrinterEvent
              {...routeProps}
              eventData={printerData.subscriber}
              eventType={"subscriber"}
              eventTypeName={"Subscribers"}
              changeActiveState={changeActiveState}
              printerID={id}
            />
          )}
        />
        <Route
          path={url + "/resubs"}
          render={(routeProps) => (
            <PrinterEvent
              {...routeProps}
              eventData={printerData.resub}
              eventType={"resub"}
              eventTypeName={"Resubs"}
              changeActiveState={changeActiveState}
              printerID={id}
            />
          )}
        />
        <Route
          path={url + "/giftsubs"}
          render={(routeProps) => (
            <PrinterEvent
              {...routeProps}
              eventData={printerData.giftsub}
              eventType={"giftsub"}
              eventTypeName={"Giftsubs"}
              changeActiveState={changeActiveState}
              printerID={id}
            />
          )}
        />
        <Route
          path={url + "/bits"}
          render={(routeProps) => (
            <PrinterEvent
              {...routeProps}
              eventData={printerData.cheer}
              eventType={"cheer"}
              eventTypeName={"Bits"}
              changeActiveState={changeActiveState}
              printerID={id}
            />
          )}
        />
        <Route
          path={url + "/raids"}
          render={(routeProps) => (
            <PrinterEvent
              {...routeProps}
              eventData={printerData.raid}
              eventType={"raid"}
              eventTypeName={"Raids"}
              changeActiveState={changeActiveState}
              printerID={id}
            />
          )}
        />
        <Route
          path={url + "/hosts"}
          render={(routeProps) => (
            <PrinterEvent
              {...routeProps}
              eventData={printerData.host}
              eventType={"host"}
              eventTypeName={"Hosts"}
              changeActiveState={changeActiveState}
              printerID={id}
            />
          )}
        />
        {printerData.webshop && (
          <Route
            path={url + "/webshop"}
            render={(routeProps) => (
              <PrinterEvent
                {...routeProps}
                eventData={printerData.webshop}
                eventType={"webshop"}
                eventTypeName={"Webshop"}
                changeActiveState={changeActiveState}
                printerID={id}
              />
            )}
          />
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="flex flex-row justify-between bg-white px-8">
        <h2 className="text-3xl py-5 text-gray-800 font-semibold">
          Stream Printer
        </h2>
        <div className=" flex items-center">
          <h3 className="font-semibold px-8">Printer on/off</h3>
          <span
            id="c1"
            onClick={() => {
              firestorePrinterPath.update({ active: !printerData.active });
            }}
            className={
              toggleColor +
              " bg-gray-300 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
            }
          >
            <span
              className={
                togglePos +
                " translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
              }
            ></span>
          </span>
        </div>
      </div>

      <div className="flex flex-col">
        <ProductNav printerData={printerData} />

        <div className="bg-gray-200">
          <div className="flex justify-between mt-20 mb-20 px-10">
            {printerEventContent}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Printer;
