import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyCe9X5Ig31aol128RhT5JjWr6971vN_gYo",
  authDomain: "streamdoctors-dashboard.firebaseapp.com",
  databaseURL: "https://streamdoctors-dashboard.firebaseio.com",
  projectId: "streamdoctors-dashboard",
  storageBucket: "streamdoctors-dashboard.appspot.com",
  messagingSenderId: "952229041333",
  appId: "1:952229041333:web:b9d0bbc62a9cbcd7959afc",
  measurementId: "G-NDZ1MQ6HHY",
});

firebase.analytics();
export const firestore = firebase.firestore();