import React, { useContext, useState, useEffect } from "react";
import { firestore }  from '../Components/Firestore'
import { Link } from "react-router-dom";
import { UserDataContext } from "../Components/UserDataProvider";

const ProductList = () => {

  const { userData } = useContext(UserDataContext);

  const [products, setProducts] = useState(null)

  useEffect(() => { 
    firestore.collection("products").orderBy("pos", "asc").get()
      .then((products) => {

        let productArr = [];
        
        products.forEach((doc) => {
          let product = { ...doc.data(), id: doc.id};
          productArr.push(product);
        });

        setProducts(productArr);

      });
 }, []);

  let productGrid;
  if (products) {
    productGrid = (
      <div className="flex flex-row mx-auto container mt-24">
        {products.map((product) => {

          let styling;
          let link;

          if (userData.products[product.id]) {
            styling = "opacity-100"
            link = "/products/" + product.slug + "/" + userData.products[product.id]
          }
          else {
            styling = "opacity-25"
            link = "/buy"
          }

          return (
            <Link key={product.slug} to={link}
              className={styling + " w-64 group mr-8 cursor-pointer opacity-25 bg-white rounded-lg shadow hover:shadow-2xl transition-shadow duration-300 ease-in-out"}
            >
              <div className="justify-center flex">
                <img className="h-48 -mt-8 pb-4 select-none pointer-events-none" alt="product_logo" src={product.img} />
              </div>
              <div className="bg-gray-300 py-4 rounded-b-lg flex flex-col items-center justify-between text-center">
                <h2 className="font-semibold text-xl text-gray-800">{product.name}</h2>
              </div>
            </Link>
          );
        })}
      </div>
    );
  }

  return (
    <React.Fragment>
      {productGrid}
    </React.Fragment>
  );
};

export default ProductList;
